.sub-banner--image .sub-banner__item {
  background-size: cover;
  background-position: bottom center;
  background-repeat: no-repeat;
  height: 71.88vw;
  max-height: rem-calc(335);
  margin-bottom: rem-calc(7);
  @include breakpoint(medium up) {
    height: 35.94vw;
    max-height: rem-calc(275);
  }
  @include breakpoint(large up) {
    height: 15.97vw;
    margin: 0;
  }
}

.sub-banner--text .sub-banner__item {
  max-width: 100%;
  flex: 0 0 100%;
  margin-bottom: rem-calc(30);
  @include breakpoint(medium up) {
    max-width: 33.29%;
    flex: 0 0 33.29%;
    margin-bottom: 0;
  }
  @include breakpoint(large up) {
    max-width: 25%;
    flex: 0 0 25%;
  }
}

.website-type__container {
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  .website-type__item {
    margin-bottom: rem-calc(30);
    @include breakpoint(medium only) {
      width: 49.7%;
    }
    @include breakpoint(large up) {
      margin-bottom: rem-calc(35);
    }
    &:nth-child(1) {
      margin-bottom: rem-calc(0);
      @include breakpoint(medium up) {
        order: 1;
      }
      @include breakpoint(large up) {
        order: 1;
      }
    }
    &:nth-child(2) {
      .title {
        color: $white;
      }
      @include breakpoint(medium up) {
        order: 3;
      }
      @include breakpoint(large up) {
        order: 2;
      }
    }
    &:nth-child(3) {
      margin-bottom: rem-calc(0);
      @include breakpoint(medium up) {
        order: 2;
      }
      @include breakpoint(large up) {
        order: 4;
      }
    }
    &:nth-child(4) {
      @include breakpoint(medium up) {
        order: 4;
      }
      @include breakpoint(large up) {
        order: 3;
      }
    }
    &__image {
      position: relative;
    }
    .title {
      display: block;
      top: 65px;
      position: absolute;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
    }
    .button {
      display: block;
      top: 110px;
      position: absolute;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      max-width: 210px;
    }
  }
}
