$lato: (
  stack: ('Lato', sans-serif),
  regular: (
    font-weight: 400,
    font-style: normal,
  ),
  regular-italic: (
    font-weight: 400,
    font-style: italic,
  ),
  bold: (
    font-weight: 700,
    font-style: normal,
  ),
  black: (
    font-weight: 900,
    font-style: normal,
  ),
);
