.title {
  @include font($lato, bold);
  font-size: rem-calc(20);
  line-height: rem-calc(22);
  letter-spacing: calc-letter-spacing(100);
  text-transform: uppercase;
  @include breakpoint(medium up) {
    font-size: rem-calc(34);
    line-height: rem-calc(34);
  }
  &.title--secondary {
    font-size: rem-calc(20);
    line-height: rem-calc(20);
    letter-spacing: calc-letter-spacing(200);
  }
  &.title--main {
    font-size: rem-calc(16);
    line-height: rem-calc(20);
    letter-spacing: calc-letter-spacing(100);
    margin-bottom: rem-calc(15);
  }
  &.title--small {
    font-size: rem-calc(14);
    line-height: rem-calc(22);
    letter-spacing: calc-letter-spacing(100);
  }
  &.title--line {
    margin-bottom: rem-calc(15);
    &:after {
      content: ' ';
      display: block;
      background: $information-blue;
      height: rem-calc(1);
      width: rem-calc(60);
      margin: rem-calc(13 0 0);
    }
    &.text-center {
      &:after {
        margin: rem-calc(10) auto rem-calc(0);
      }
    }
  }
}
p, .body-copy, li, %body-copy {
  @include font($lato);
  font-size: rem-calc(14);
  line-height: rem-calc(24);
  &.body-copy--xlarge, %body-copy--xlarge {
    font-size: rem-calc(18);
    line-height: rem-calc(28);
  }
  &.body-copy--large, %body-copy--large {
    @include font($lato, black);
    font-size: rem-calc(16);
    line-height: rem-calc(20);
  }
  &.body-copy--small, %body-copy--small {
    font-size: rem-calc(12);
    line-height: rem-calc(20);
  }
  &.body-copy--italic, %body-copy--italic {
    @include font($lato, regular-italic);
  }
  &.body-copy--uppercase, %body-copy--uppercase {
    text-transform: uppercase;
  }
  &.body-copy--bold, %body-copy--bold {
    @include font($lato, bold);
  }
  .divide {
    display: inline-block;
    padding: rem-calc(0 10);
  }
}
p a {
  color: $information-blue;
  &:hover {
    text-decoration: underline;
  }
}
.carousel {
  p {
    color: $dusty-grey;
  }
}
.main__lists .title--line {
  text-align: center;
  &:after {
    margin: rem-calc(10) auto rem-calc(0);
  }
  @include breakpoint(medium up) {
    text-align: left;
    &:after {
      margin: rem-calc(10 0 0);
    }
  }
}
/* ==========================================================================
   Off Canvas
   ========================================================================== */
.off-canvas-extra .off-canvas-extra__contact {
  p {
    color: $white;
      a {
      color: $white;
      display: block;
      width: 100%;
    }
  }
  .divide {
    display: none;
  }
}

/* ==========================================================================
   hero
   ========================================================================== */
.hero__section {
  .title {
    text-shadow: 0px 0px 15px rgba(0, 0, 0, 1);
    margin-bottom: rem-calc(40);
  }
}
.hero--inner .hero__section .title {
  @include breakpoint(large up) {
    margin-bottom: 0;
  }
}
/* ==========================================================================
   sub banner
   ========================================================================== */
a.body-copy--normal {
  color: $oil-grey;
  font-size: rem-calc(16);
  &:hover {
    color: $oil-grey;
  }
}
a.body-copy--invert {
  color: $white;
  font-size: rem-calc(16);
  &:hover {
    color: $white;
  }
}
a.body-copy:hover{
  text-decoration: underline;
}
.sub-banner--image .sub-banner__item {
  .title {
    color: $white;
    width: auto;
    display: inline-block;
    margin: rem-calc(16 0 0);
    padding: rem-calc(0 5);
    letter-spacing: calc-letter-spacing(200);
  }
  &.sub-banner__item--website .title {
    background: $website-red;
  }
  &.sub-banner__item--app .title {
    background: $app-green;
  }
  &.sub-banner__item--seo .title {
    background: $seo-blue;
  }
  &.sub-banner__item--about .title {
    background: $information-blue;
  }
}

.sub-banner__item {
  p {
    color: $dusty-grey;
  }
  .title {
    letter-spacing: calc-letter-spacing(200);
  }
}

.sub-banner--text {
  flex-wrap: wrap;
  @include breakpoint(large up) {
    justify-content: space-around;
  }
  .title {
    padding-left: rem-calc(35);
    position: relative;
    svg {
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}
/* ==========================================================================
   Inner Pages
   ========================================================================== */
 sup {
   padding-left: rem-calc(2);
   color: $information-blue;
 }
.main {
  p, .body-copy {
    color: $dusty-grey;
    margin-bottom: rem-calc(25);
  }
  .title, .body-copy--xlarge {
    color: $oil-grey;
  }
  .body-copy--xlarge {
    margin-bottom: rem-calc(45);
  }
}
.page--bespoke-websites, .page--bespoke-apps {
   .main .body-copy--xlarge {
    margin-bottom: rem-calc(30);
    @include breakpoint(medium down) {
      margin-bottom: rem-calc(20);
    }
  }
}
.bold-list {
  list-style: none;
  margin: rem-calc(0 0 20);
  li {
    color: $oil-grey;
    @include font($lato, bold);
    font-size: rem-calc(12);
    letter-spacing: calc-letter-spacing(100);
    text-transform: uppercase;
  }
}

.features-box__item {
  .title {
    margin: 0;
  }
  p {
    color: $dusty-grey;
    font-size: rem-calc(12);
    line-height: rem-calc(16);
    text-transform: uppercase;
    margin: 0;
  }
}

.feature-list {
  list-style: none;
  margin: rem-calc(0 0 40);
  li {
    color: $dusty-grey;
    font-weight: 700;
    font-size: rem-calc(12);
    line-height: rem-calc(16);
    text-transform: uppercase;
    position: relative;
    padding: rem-calc(12 0 12 28);
    border-bottom: 1px solid $callback-grey;
    &::before {
      position: absolute;
      content: url('../img/check_list.png');
      width: rem-calc(16);
      height: rem-calc(16);
      left: 0;
    }
  }
}

.packages-desc p {
  line-height: rem-calc(20);
}

.cta {
  .cta__description {
    p {
      @include breakpoint(large up) {
        font-size: rem-calc(16)
      }
    }
  }
}
.app-packages__item {
  p {
    width: 90%;
    margin: 0 auto;
  }
  &.app-packages__item--packages .app-packages__item-overlay {
    .title, p {
      color: $white;
    }
  }
  &.app-packages__item--internal .app-packages__item-overlay {
    .title {
      color: $white;
    }
  }
}
/* ==========================================================================
   footer
   ========================================================================== */
.top-footer__section {
  .title {
    position: relative;
    color: $white;
    margin: rem-calc(28 0 25);
    &:after {
      content: ' ';
      display: block;
      background: $information-blue;
      height: rem-calc(2);
      width: rem-calc(30);
      margin-top: rem-calc(25);
    }
  }
}
.footer-menu li {
  list-style-image: url('../img/chevron_grey.png');
  &:hover {
    list-style-image: url('../img/chevron_white.png');
  }
  a {
    color: $callback-grey;
    @extend %body-copy--small;
    &:hover {
      color: $white;
      text-decoration: underline;
    }
  }
}
.footer-contact-details {
  a {
    position: relative;
    color: $callback-grey;
    &:hover {
      color: $white;
      text-decoration: underline;
    }
  }
  a.telephone-number {
    color: $white;
    @include font($lato, black);
    font-size: rem-calc(16);
    line-height: rem-calc(20);
    letter-spacing: calc-letter-spacing(50);
  }
}
/* ==========================================================================
   bespoke websites
   ========================================================================== */
.title.title--bespoke-services {
  text-transform: none;
  margin-bottom: rem-calc(30);
  font-size: rem-calc(18);
  font-weight: normal;
  letter-spacing: 0;
}
