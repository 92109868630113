.page {
  &--packages {
    .main {
      margin-bottom: 0;
    }
  }
  &--bespoke-websites {
    .row--flex.sub-banner {
      justify-content: center;
      & > div {
        @include breakpoint(medium only) {
          max-width: 49.8%;
          flex: 0 0 49.8%;
        }
      }
    }
  }
}
