.header-cta__section {
  text-align: center;
  padding-top: rem-calc(10);
  padding-bottom: rem-calc(10);
  @include breakpoint(medium up) {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    &.header-cta__section--right {
      justify-content: flex-end;
    }
  }
  &.header-cta__section--social-media {
    background: $dark-information-blue;
    justify-content: space-around;
  }
  p {
    margin: 0;
    color: $white;
    a {
      color: $white;
    }
  }
}

.header__section {
  &.header__section--logo {
    padding-top: rem-calc(25);
  }
  &.header__section--navigation {
    padding-top: rem-calc(40);
  }
  &.header__section--cta {
    padding-top: rem-calc(35);
    .button {
      width: rem-calc(210);
      float: right;
      margin-right: rem-calc(-10);
      @include breakpoint(medium up) {
        margin-right: rem-calc(-15);
      }
    }
  }
  &.header__section--off-canvas {
    padding-top: rem-calc(40);
  }
}
