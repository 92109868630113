.carousel {
  background: $eddy-grey;
  padding: rem-calc(40 0);
}
.website-carousel {
  margin-top: rem-calc(10);
  &.website-carousel--show-new {
    .website-carousel__item--new {
      display: block;
    }
    .website-carousel__item--old {
      display: none;
    }
  }
  &.website-carousel--show-old {
    .website-carousel__item--new {
      display: none;
    }
    .website-carousel__item--old {
      display: block;
    }
  }
}
