.menu--left-to-right {
    padding: 0;
    margin: 0;
  li {
    padding: 0;
    list-style: none;
    display: inline-block;
    a {
      @include font($lato, bold);
      font-size: rem-calc(12);
      color: $oil-grey;
      letter-spacing: calc-letter-spacing(100);
      text-decoration: none;
      text-transform: uppercase;
      position: relative;
      padding: rem-calc(10 0);
      margin: rem-calc(0 20);
      &:before, &:after {
        content: '';
        display: block;
        position: absolute;
        height: 1px;
        width: 0;
        bottom: 0;
      }
      &:before {
        left: 0;
        transition: width 0s ease, background .5s ease;
      }
      &:after {
        right: 0;
        background: $information-blue;
        transition: width .5s ease;
      }
      &[href^="/websites"] {
        &:after {
          background: $website-red;
        }
      }
      &[href^="/apps"] {
        &:after {
          background: $app-green;
        }
      }
      &[href^="/seo"] {
        &:after {
          background: $seo-blue;
        }
      }
      &:hover, &.active {
        &:before, &:after {
          width: 100%;
        }
        &:before {
          background: $information-blue;
          transition: width .5s ease;
        }
        &:after {
          background: transparent;
          transition: all 0s ease;
        }
        &[href^="/websites"] {
          &:before {
            background: $website-red;
          }
        }
        &[href^="/apps"] {
          &:before {
            background: $app-green;
          }
        }
        &[href^="/seo"] {
          &:before {
            background: $seo-blue;
          }
        }
      }
    }
  }
}

.off-canvas .menu {
  li {
    &:focus {
      outline: none;
    }
    a {
      @include font($lato, bold);
      text-transform: uppercase;
      font-size: rem-calc(16);
      color: $white;
      &:focus {
        outline: none;
      }
    }
  }
}
