.hero {
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
  .row {
    height: rem-calc(410);
    min-height: rem-calc(410);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: rem-calc(7);
  }
  &--inner .row {
    height: rem-calc(253);
    min-height: rem-calc(253);
    max-width: rem-calc(948);
    justify-content: center;
    margin-bottom: rem-calc(70);
    @include breakpoint(large up) {
      justify-content: flex-start;
    }
  }
  &.hero--no-image {
    background: $mercury-grey;
    .row {
      height: rem-calc(105);
      min-height: rem-calc(105);
      margin-bottom: rem-calc(65);
      p{
        font-weight: 400;
        margin: 0;
      }
    }
  }
}
