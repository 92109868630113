/* ==========================================================================
   buttons
   ========================================================================== */

.button, button {
  background: $white;
  color: $oil-grey;
  width: 100%;
  padding-top: 0;
  padding-bottom: 0;
  height: rem-calc(50);
  line-height: rem-calc(50);
  margin-bottom: rem-calc(20);
  @include font($lato, bold);
  font-size: rem-calc(14);
  text-transform: uppercase;
  letter-spacing: calc-letter-spacing(100);
  transition: all 350ms ease;
  span {
    text-align: left;
    float: left;
  }
  svg.icon {
    line-height: rem-calc(49);
    float: right;
    text-align: right;
    width: rem-calc(16);
    height: rem-calc(49);
    fill: $oil-grey;
  }
  &:hover {
    background: $white;
    padding-right: rem-calc(10);
    color: $oil-grey;
  }
  &:focus, &:active  {
    color: $oil-grey;
    background: darken($white, 5%);
  }
  &.button--small {
    height: rem-calc(40);
    line-height: rem-calc(40);
    svg.icon {
      line-height: rem-calc(39);
      height: rem-calc(39);
    }
  }
  &.button--secondary {
    background: $website-red;
    color: $white;
    svg.icon {
      fill: $white;
    }
    &:hover {
      background: $website-red;
    }
    &:focus, &:active  {
      background: darken($website-red, 5%);
    }
  }
  &.button--tertiary {
    background: $oil-grey;
    color: $white;
    svg.icon {
      fill: $white;
    }
    &:hover {
      background: $oil-grey;
    }
    &:focus, &:active  {
      background: darken($oil-grey, 5%);
    }
  }
  &.button--transparent {
    background: none;
    color: $information-blue;
    max-width: rem-calc(165);
    padding-left: 0;
    letter-spacing: 0;
    svg.icon {
      fill: $information-blue;
    }
    &:hover {
      background: none;
    }
    &:focus, &:active  {
      background: none;
    }
  }
  &.button--toggle {
    background: $alto-grey;
    color: $dusty-grey;
    border: 1px solid $callback-grey;
    @include font($lato);
    font-size: rem-calc(12);
    width: calc(50% - 1.5px);
    min-width: rem-calc(85);
    padding: rem-calc(0 5);
    margin: 0;
    border-radius: 4px;
    position: relative;
    z-index: 100;
    @include breakpoint(medium up) {
      min-width: rem-calc(104);
    }
    &.button--toggle-old {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      margin-right: -1.5px;
    }
    &.button--toggle-new {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      margin-left: -1.5px;
    }
    &:hover, &.button--toggle-active {
      background: $information-blue;
      color: $white;
      border-color: $dark-information-blue;
    }
    &.button--toggle-active {
      box-shadow: 0px 0px 4.9px 2.1px rgba(0, 188, 212, 0.55);
      z-index: 150;
    }
    &:focus, &:active  {
      background: $dark-information-blue;
    }
  }
  &.button--carousel {
    background: $alto-grey;
    color: $dusty-grey;
    border: 1px solid $callback-grey;
    font-size: rem-calc(12);
    max-width: rem-calc(105);
    padding: rem-calc(0 16);
    border-radius: 4px;
    width: auto;
    .icon {
      position: relative;
      width: rem-calc(10);
      height: rem-calc(15);
      fill: $oil-grey;
      right: 0;
      transition: right 350ms ease;
    }
    &.slick-prev {
      transform: rotate(180deg);
    }
    &:hover {
      background: $information-blue;
      border-color: $dark-information-blue;
      .icon {
        fill: $white;
        right: rem-calc(-5);
      }
    }
    &:focus, &:active  {
      background: $dark-information-blue;
      .icon {
        fill: $white;
      }
    }
  }
  &.button--alternative {
    font-size: rem-calc(12);
    min-width: rem-calc(246);
    span {
      text-align: right;
      float: right;
    }
    svg.icon {
      float: left;
      text-align: left;
      transform: rotate(-180deg);
    }
    &:hover {
      padding-left: rem-calc(10);
      padding-right: rem-calc(20);
    }
  }
}

input[type="button"]:hover, input[type="button"].button--alternative:hover {
  padding-left: rem-calc(20);
  padding-right: rem-calc(20);
}
.header {
  .button {
    margin: 0;
  }
}
.hero {
  .button {
    max-width: rem-calc(280);
    margin: 0 auto;
  }
}

.top-footer__section .button {
  margin-top: rem-calc(5);
}

/* ==========================================================================
   Social Icons
   ========================================================================== */

.social-icon {
  display: inline-block;
  font-size: rem-calc(18);
  text-align: center;
  color: $white;
  padding: 0;
  margin: rem-calc(0 10);
  transition: all 350ms ease;
  min-width: rem-calc(25);
  height: rem-calc(25);
  @include breakpoint(medium up) {
    margin: rem-calc(0);
  }
  &:hover {
    background: $white;
    color: $dark-information-blue;
  }
  .fa {
    line-height: rem-calc(18);
    padding-top: rem-calc(3);
  }
  .fa-facebook {
    font-size: rem-calc(17);
  }
}
.dark-social-links .social-icon {
  font-size: rem-calc(14);
  background: $dark-rtd-blue;
  border: 1px solid transparent;
  &:hover {
    background: $dark-rtd-blue;
    border-color: $white;
    color: $white;
  }
  .fa-facebook {
    font-size: rem-calc(14);
  }
}
/* ==========================================================================
   images
   ========================================================================== */
.logo {
  width: rem-calc(227);
  height: rem-calc(64);
}
.results-title {
  width: rem-calc(230);
  height: rem-calc(65);
  margin-bottom: rem-calc(15);
}
.sidebar-img {
  margin-bottom: rem-calc(35);
}
/* ==========================================================================
   icons
   ========================================================================== */

.sub-banner {
  .title svg:not(.icon-arrow) {
    fill: $dusty-grey;
    stroke: $dusty-grey;
    width: rem-calc(24);
    height: rem-calc(26);
    margin-right: rem-calc(10);
  }
  .sub-banner__item--mobile {
    .title svg {
      stroke: none;
      left: rem-calc(-5);
    }
  }
}

.header__section--off-canvas {
  .off-canvas-link {
    float: right;
    @include font($lato, bold);
    font-size: rem-calc(14);
    text-transform: uppercase;
    margin: 0;
  }
  .icon {
    fill: $oil-grey;
    width: rem-calc(30);
    height: rem-calc(30);
  }
  a {
    color: $oil-grey;
  }
}

.hamicon {
  line-height: rem-calc(14);
  float: right;
}
.hamburger-icon {
 width: rem-calc(28);
 height: rem-calc(20);
 .hamburger-icon__container {
   fill: $dusty-grey;
   .hamburger-icon__line {
    transition: transform 400ms ease-out;
    transform-origin: 14px 10px;
   }
   .hamburger-icon__middle {
     transform: scale(1);
   }
 }
}
.hamicon.active {
 .hamburger-icon {
   .hamburger-icon__top {
     transform: rotate(-45deg)  translate(0, 8px);
   }
   .hamburger-icon__bottom {
     transform: rotate(45deg)  translate(0, -6px);
   }
   .hamburger-icon__middle {
     transform: scale(0);
   }
 }
}

.features-box__item {
  .icon {
    fill: $dusty-grey;
  }
  .icon-devices {
    stroke: $dusty-grey;
    width: rem-calc(67);
    height: rem-calc(30);
  }
  .icon-agreement {
    width: rem-calc(41);
    height: rem-calc(24);
  }
  .icon-network {
    width: rem-calc(38);
    height: rem-calc(29);
  }
  .icon-call-person {
    width: rem-calc(32);
    height: rem-calc(35);
  }
  .icon-boost {
    width: rem-calc(60);
    height: rem-calc(30);
  }
  .icon-meeple {
    width: rem-calc(36);
    height: rem-calc(28);
  }
  .icon-point {
    width: rem-calc(34);
    height: rem-calc(28);
  }
  .icon-speech {
    stroke: $dusty-grey;
    width: rem-calc(34);
    height: rem-calc(33);
  }
  .icon-notebook {
    stroke: $dusty-grey;
    width: rem-calc(26);
    height: rem-calc(30);
  }
  .icon-saving {
    stroke: $dusty-grey;
    width: rem-calc(30);
    height: rem-calc(30);
  }
  .icon-idea {
    width: rem-calc(36);
    height: rem-calc(39);
  }
  .icon-award {
    stroke: $dusty-grey;
    width: rem-calc(31);
    height: rem-calc(33);
  }
  .icon-calendar {
    stroke: $dusty-grey;
    width: rem-calc(30);
    height: rem-calc(30);
  }
  .icon-response {
    stroke: $dusty-grey;
    width: rem-calc(28);
    height: rem-calc(30);
  }
}
