.carousel-arrows {
  margin-top: rem-calc(15);
  position: relative;
  .button--carousel {
    position: absolute;
    top: 0;
  }
  .slick-prev {
    left: calc(#{map-get($grid-column-gutter, small)} /2);
    @include breakpoint(medium up) {
      left: calc(#{map-get($grid-column-gutter, medium)} /2);
    }
  }
  .slick-next {
    right: calc(#{map-get($grid-column-gutter, small)} /2);
    @include breakpoint(medium up) {
      right: calc(#{map-get($grid-column-gutter, medium)} /2);
    }
  }
  .toggle-buttons {
    width: 60%;
    margin: 0 auto;
    @include breakpoint(medium up) {
      width: 70%;
      max-width: rem-calc(215);
    }
    @include breakpoint(xlarge up) {
      width: 50%;
      max-width: rem-calc(215);
    }
  }
}

.website-carousel__item {
  box-shadow: 2px 3px 7px 0px rgba(0, 0, 0, 0.3);
}
