@function map-deep-get($map, $keys...) {
  @each $key in $keys {
    $map: map-get($map, $key);
  }

  @return $map;
}

@function calc-letter-spacing($kerningValue) {
  @return #{$kerningValue / 1000}em;
}

@mixin font($font-stack, $variant: regular, $properties: color family weight size style spacing) {
  font-family: map-get($font-stack, stack);
  $font-properties: map-deep-get($font-stack, $variant);
  @if $font-properties {
    @each $property, $values in $font-properties {
      @if contains($properties, $property) {
        #{$property}: map-get($font-properties, $property);
      }
    }
  }
}

@mixin placeholder {
  &::-webkit-input-placeholder {@content}
  &:-moz-placeholder           {@content}
  &::-moz-placeholder          {@content}
  &:-ms-input-placeholder      {@content}
}
