/* ==========================================================================
   Primary Colours
   ========================================================================== */
   $rtd-blue:               #0C5878;
   $dark-rtd-blue:          #003A52;

/* ==========================================================================
  Secondary Colours
  ========================================================================== */
  $information-blue:        #00BCD5;
  $dark-information-blue:   #029DB3;
  $seo-blue:                #00AEEF;
  $app-green:               #93D801;
  $website-red:             #E6361E;

/* ==========================================================================
   Accent Colours
   ========================================================================== */
   $eddy-grey:              #EDEDED;
   $mercury-grey:           #E9E9E9;
   $alto-grey:              #DDDDDD;
   $callback-grey:          #CCCCCC;
   $dusty-grey:             #999999;
   $oil-grey:               #474747;
