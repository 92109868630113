.alternating-content {
  & > .row--flex {
    margin-bottom: rem-calc(20);
    @include breakpoint(large up) {
      margin-bottom: rem-calc(50);
      align-items: center;
      justify-content: center;
    }
    img {
      width: 100%;
      margin-bottom: rem-calc(20);
      @include breakpoint(medium up) {
        margin-bottom: 0;
      }
      @include breakpoint(large up) {
        max-width: rem-calc(335);
      }
    }
    & > div {
      @include breakpoint(medium only) {
        width: 49.8%;
      }
      .button {
        margin: 0;
      }
    }
    &:nth-child(odd) {
      div {
        &:nth-child(1) {
          @include breakpoint(medium up) {
            order: 2;
            text-align: right;
          }
        }
        &:nth-child(2) {
          @include breakpoint(medium up) {
            order: 1;
          }
        }
      }
    }
  }
}
