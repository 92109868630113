.top-footer__section {
  color: $callback-grey;
  hr {
    margin-top: 0;
    border-color: $dark-rtd-blue;
  }
}
.bottom-footer__section {
  padding-top: rem-calc(16);
  padding-bottom: rem-calc(16);
  p, a {
    color: $white;
    margin: 0;
    font-size: rem-calc(12);
  }
  a:not(:first-of-type) {
    margin-left: rem-calc(10);
  }
}

.footer-contact-details {
  position: relative;
  padding-left: rem-calc(26);
  svg.icon {
    position: absolute;
    left: rem-calc(-26);
    top: rem-calc(3);
    fill: $white;
    width: rem-calc(18);
    height: rem-calc(18);
  }
}
