.hero__section {
  color: $white;
  &.small-12 {
    max-width: 100%;
    flex: 0 0 100%;
  }
  @include breakpoint(large up) {
    &.large-9 {
      max-width: 74%;
      flex: 0 0 74%;
    }
    &.large-3 {
      max-width: 25%;
      flex: 0 0 25%;
    }
  }
}
