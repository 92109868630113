label          {
  color: $oil-grey;
  font-size:rem-calc(14);
  font-weight:700;
  text-transform: uppercase;
  position:absolute;
  pointer-events:none;
  top:rem-calc(12);
  padding-left: rem-calc(20);
  transition:0.2s ease all;
}
span.required {
  color: $website-red;
}
form p {
  color: $dusty-grey;
  @include breakpoint(medium up) {
    padding-left: map-get($grid-column-gutter, medium);
  }
}
/* active state */
input[type='text'], input[type='password'], input[type='date'], input[type='datetime'], input[type='datetime-local'], input[type='month'], input[type='week'], input[type='email'], input[type='number'], input[type='search'], input[type='tel'], input[type='time'], input[type='url'], input[type='color'], textarea {
  height: rem-calc(50);
  border: 1px solid $callback-grey;
  margin-bottom: rem-calc(20);
  padding: rem-calc(0 20);
  &:focus ~ label, &.used ~ label     {
    top: 0;
    font-size: rem-calc(10);
    color: $callback-grey;
  }
}
textarea {
  height: rem-calc(130);
  padding: rem-calc(20);
}
.form__element {
  position: relative;
  @include breakpoint(small only) {
    padding: 0;
  }
  &.form__element--submit {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    @include breakpoint(medium up) {
      flex-wrap: nowrap;
    }
  }
}

form .button {
  @include breakpoint(medium up) {
    max-width: rem-calc(210);
    float: left;
  }
}
