.main__lists {
  margin-top: rem-calc(45);
  margin-left: $grid-gutter-small;
  margin-right: $grid-gutter-small;
  @include breakpoint(medium up) {
    margin-left: $grid-gutter-medium;
    margin-right: $grid-gutter-medium;
  }
}
.features-box {
  border: 1px solid $callback-grey;
  list-style: none;
  margin: rem-calc(0 0 35);
  padding: rem-calc(25 0);
  @include clearfix;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  .features-box__item {
    box-sizing: border-box;
    float: left;
    max-width: 50%;
    flex: 0 0 50%;
    display: inline-block;
    padding: rem-calc(0 17);
    text-align: center;
    margin-bottom: rem-calc(15);
    @include breakpoint(medium up) {
      max-width: 25%;
      flex: 0 0 25%;
    }
    @include breakpoint(large up) {
      max-width: 18.45%;
      flex: 0 0 18.45%;
      margin-bottom: 0;
    }
    &.features-box__item--title {
      max-width: 100%;
      flex: 0 0 100%;
      margin-bottom: rem-calc(30);
      @include breakpoint(large up) {
        max-width: 26%;
        margin-bottom: 0;
        flex: 0 0 26%;
      }
    }
  }
  &.features-box--stacked {
    padding-bottom: 0;
    justify-content: center;
    .features-box__item {
      max-width: 50%;
      padding: rem-calc(0 18);
      flex: 0 0 50%;
      @include breakpoint(medium up) {
        max-width: 33.2%;
        flex: 0 0 33.2%;
      }
      @include breakpoint(large up) {
        margin-bottom: rem-calc(30);
      }
      @include breakpoint(xlarge up) {
        max-width: 24.5%;
        flex: 0 0 24.5%;
      }
      &.features-box__item--title {
        @include breakpoint(medium down) {
          max-width: 100%;
          flex: 0 0 100%;
        }
      }
    }
  }
}

.cta {
  background: $eddy-grey;
  padding: rem-calc(30 0 10);
  margin-top: rem-calc(40);
  @include breakpoint(medium up) {
    margin-top: rem-calc(90);
  }
  @include breakpoint(large up) {
    margin-top: rem-calc(120);
  }
  @include breakpoint(xlarge up) {
    margin-top: rem-calc(90);
  }
  .row {
    position: relative;
  }
  .cta__image {
      position: relative;
      bottom: rem-calc(-10);
    @include breakpoint(medium up) {
      position: absolute;
      top: auto;
    }
  }
}

.app-packages {
  .app-packages__item {
    position: relative;
    margin-bottom: rem-calc(40);
    max-width: rem-calc(285);
    img {
      width: 100%;
    }
  }
  .app-packages__item-overlay {
    width: 100%;
    padding: 0 $grid-gutter-small;
    position: absolute;
    top: rem-calc(110);
    left: 0;
    @include breakpoint(medium up) {
      padding: 0 $grid-gutter-medium;
    }
  }
}

.package-footnote {
  border: 1px solid $callback-grey;
  padding: rem-calc(18 18 0);
   p {
     color: $oil-grey;
   }
}
