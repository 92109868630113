.header-cta {
  background: $information-blue;
  min-height: rem-calc(45);
  @include breakpoint(medium up) {
    display: flex;
  };
}
.header {
  padding-bottom: rem-calc(15);
  border-top: 5px solid $information-blue;
  @include breakpoint(medium up) {
    border-top: none;
  }
}
