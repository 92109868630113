.main {
  margin-bottom: rem-calc(40);
  @include breakpoint(large up) {
    min-height: rem-calc(200);
  }
}
.contact-sidebar {
  margin-bottom: rem-calc(20);
  .contact-sidebar__item {
    position: relative;
    padding-left: rem-calc(40);
  }
  svg {
    position: absolute;;
    left: 0;
    fill: $dusty-grey;
  }
  .icon-pin {
    height: rem-calc(22);
    width: rem-calc(18);
  }
  .icon-phone {
    height: rem-calc(32);
    width: rem-calc(22);
  }
  .icon-mail {
    height: rem-calc(17);
    width: rem-calc(22);
  }
  a, address {
    color: $dusty-grey;
  }
  address {
    margin-bottom: rem-calc(16);
    font-size: rem-calc(14);
    font-style: normal;
  }
}
form {
  margin-bottom: rem-calc(20);
}

.app-packages {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: rem-calc(42);
  &::before, &::after {
    content: none;
  }
  @include breakpoint(medium up) {
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
  }
  @include breakpoint(large up) {
    justify-content: space-between;
    margin-left: rem-calc(-15);
    margin-right: rem-calc(-15);
  }
}
